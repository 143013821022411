import { Injectable } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { ConfigJson } from '../interfaces/config-generator';

@Injectable({
  providedIn: 'root'
})
export class ConfigGeneratorService {
  constructor(private fb: FormBuilder) {}

  private sortObject(obj: ConfigJson): ConfigJson {
    return Object.entries(obj)
      .sort()
      .reduce((o, [k, v]) => ((o[k] = v), o), {});
  }

  public createForm(input: ConfigJson): FormArray {
    const sorted = this.sortObject(input);
    return this.fb.array(
      Object.entries(sorted).map(([key, val]: [string, string | string[]]) =>
        this.fb.array([this.fb.control(key), this.fb.control(typeof val === 'object' ? JSON.stringify(val) : val)])
      )
    );
  }

  public createFormArray(keyName: string): FormArray {
    return this.fb.array([this.fb.control(keyName), this.fb.control('')]);
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  public formatBytes(bytes, decimals?: any) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
}
