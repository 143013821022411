import { Component, OnInit } from '@angular/core';
import { ConfigGeneratorService } from './services/config-generator.service';
import { FormGroup, FormArray } from '@angular/forms';
import { saveAs } from '@progress/kendo-file-saver';
import * as zip from 'jszip';
import version from './ver.json';

@Component({
  selector: 'app-config-generator',
  templateUrl: './config-generator.component.html',
  styleUrls: ['./config-generator.component.scss']
})
export class ConfigGeneratorComponent implements OnInit {
  public editForm: FormArray;
  public forms = [];
  public files: any[] = [];
  public selectedConfig: string;
  public applyTo = [];
  public version: string;
  constructor(public configGeneratorService: ConfigGeneratorService) {
    this.version = version[0].ver;
  }

  ngOnInit() {}

  patchValue(i: number, j: number, value: string, all: boolean): void {
    const keyToChange = this.editForm.controls[i].value[0];
    if (all) {
      this.forms.forEach((form, i) => {
        if (this.applyTo[i]) {
          if (form.controls[i] && keyToChange === form.controls[i].value[0]) {
            (<FormGroup>form.controls[i]).controls[j].patchValue(value);
          } else {
            const foundControlIndex = form.value.findIndex((el) => el[0] === keyToChange);
            if (foundControlIndex !== -1) {
              (<FormGroup>form.controls[foundControlIndex]).controls[j].patchValue(value);
            }
          }
        }
      });
    } else {
      (<FormGroup>this.editForm.controls[i]).controls[j].patchValue(value);
    }
  }

  removeFormControl(i: number, all: boolean): void {
    if (all) {
      this.forms.forEach((form) => {
        form.removeAt(i);
      });
    } else {
      this.editForm.removeAt(i);
    }
  }

  addFormControl(keyname: string, all: boolean): void {
    if (all) {
      this.forms.forEach((form) => {
        form.push(this.configGeneratorService.createFormArray(keyname));
      });
    }
    this.editForm.push(this.configGeneratorService.createFormArray(keyname));
  }

  isEmptyInAll(i: number): any {
    // TO-DO
    console.log(
      this.forms.forEach((form) => {
        form.controls[i].controls[1].value;
      })
    );
  }

  triggerDownload() {
    const jszip = new zip();
    this.forms.forEach((form, i) => {
      jszip.file(this.files[i].name, JSON.stringify(Object.fromEntries(form.value)));
    });

    jszip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, 'configs.zip');
    });
  }

  changeSelectedConfig(i: number): void {
    this.selectedConfig = this.files[i].name;
    this.editForm = this.forms[i];
  }

  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files.splice(index, 1);
    this.forms.splice(index, 1);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    let index = 0;
    for (const item of files) {
      this.applyTo.push(true);
      this.files.push(item);
      this.uploadToJSON(item, index);
      index += 1;
    }
  }

  uploadToJSON(file: any, index: number) {
    const fileReader = new FileReader();
    fileReader.readAsText(file, 'UTF-8');
    fileReader.onload = () => {
      if (index === 0) {
        this.editForm = this.configGeneratorService.createForm(JSON.parse(fileReader.result as any));
        this.selectedConfig = this.files[0].name;
        this.forms.push(this.editForm);
      } else {
        this.forms.push(this.configGeneratorService.createForm(JSON.parse(fileReader.result as any)));
      }
    };
    fileReader.onerror = (error) => {
      console.error(error);
    };
  }

  trackByIdx(index: number, obj: any): any {
    return index;
  }
}
