<mat-accordion>
  <mat-expansion-panel color="primary" hideToggle [expanded]="!selectedConfig">
    <mat-expansion-panel-header>
      <mat-panel-title
        ><span class="text-white">Version: {{ version }}</span>
        <span class="mx-4">|</span>
        <span *ngIf="selectedConfig">Selected config:</span>
      </mat-panel-title>
      <mat-panel-description> {{ selectedConfig }} </mat-panel-description>
      <button *ngIf="selectedConfig" class="download-button" mat-fab color="accent" (click)="triggerDownload()">
        <mat-icon>file_download</mat-icon>
      </button>
    </mat-expansion-panel-header>
    <ng-container>
      <div class="container" appDnd (fileDropped)="onFileDropped($event)">
        <input type="file" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event.target.files)" />
        <mat-icon class="text-4xl">file_upload</mat-icon>

        <h3>Drag and drop config here</h3>
        <h3>or</h3>
        <label for="fileDropRef">Browse for file</label>
      </div>
      <div class="files-list">
        <div class="single-file" *ngFor="let file of files; let i = index; trackBy: trackByIdx">
          <div class="file-icon" style="width: 50px">
            <mat-icon>description</mat-icon>
          </div>
          <div class="info">
            <h4 class="name">
              {{ file?.name }}
            </h4>
            <p class="size">
              {{ configGeneratorService.formatBytes(file?.size) }}
            </p>
            <mat-checkbox class="example-margin" [(ngModel)]="applyTo[i]">Apply to this</mat-checkbox>
          </div>
          <div class="select" (click)="changeSelectedConfig(i)">
            <mat-icon>toggle_on</mat-icon>
          </div>

          <div class="delete" (click)="deleteFile(i)">
            <mat-icon>delete</mat-icon>
          </div>
        </div>
      </div>
    </ng-container>
  </mat-expansion-panel>
</mat-accordion>

<ng-container *ngIf="editForm">
  <div class="form">
    <div class="flex justify-end w-full">
      <input type="text" #keyName placeholder="Add key" class="w-10/12" />
      <button mat-raised-button color="accent" title="Add key" (click)="addFormControl(keyName.value, false)">
        <mat-icon>add_box</mat-icon>
      </button>
      <button mat-raised-button color="accent" title="Add key" (click)="addFormControl(keyName.value, true)">
        <mat-icon>queue</mat-icon>
      </button>
    </div>
    <form [formGroup]="editForm">
      <ng-container *ngFor="let itemIndexControl of editForm.controls; let i = index">
        <div formArrayName="{{ i }}" class="flex">
          <ng-container *ngFor="let innerLoop of itemIndexControl.controls; let j = index">
            <div formArrayName="{{ j }}" class="flex flex-row py-3" [ngClass]="{ 'is-empty': isEmptyInAll(i) }">
              <input type="text" #inputValue [value]="innerLoop.value" class="border border-black w-180" [disabled]="j === 0" />
              <button
                mat-raised-button
                color="accent"
                title="Apply to this config"
                *ngIf="j !== 0"
                (click)="patchValue(i, j, inputValue.value, false)"
              >
                <mat-icon>done</mat-icon>
              </button>
              <button
                mat-raised-button
                color="accent"
                title="Apply to all configs"
                *ngIf="j !== 0"
                (click)="patchValue(i, j, inputValue.value, true)"
              >
                <mat-icon>done_all</mat-icon>
              </button>
              <button
                mat-raised-button
                color="accent"
                title="Remove key in this config"
                *ngIf="j !== 0"
                (click)="removeFormControl(i, false)"
              >
                <mat-icon>delete</mat-icon>
              </button>
              <button
                mat-raised-button
                color="accent"
                title="Remove key in all configs"
                *ngIf="j !== 0"
                (click)="removeFormControl(i, true)"
              >
                <mat-icon>delete_sweep</mat-icon>
              </button>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </form>
  </div>
</ng-container>
