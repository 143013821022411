import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';

import { ConfigGeneratorComponent } from './config-generator.component';

import { DndDirective } from './dnd.directive';

import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  imports: [CommonModule, FormsModule, MatExpansionModule, ReactiveFormsModule, MatButtonModule, MatIconModule, MatCheckboxModule],
  declarations: [ConfigGeneratorComponent, DndDirective],
  exports: [ConfigGeneratorComponent]
})
export class ConfigGeneratorModule {}
